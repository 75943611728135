import { MegamenuItem, NavItemType } from '@/app/components/shared/Navigation/NavigationItem';

export default function ncNanoId(prefix = 'nc_') {
    return `${prefix + Date.now()}_${Math.floor(Math.random() * 9999999999)}`;
}
const megamenu = [
    {
        Company: 'Katz',
        City: 'Nezamyslice',
        Contruction: 'Plexiglass',
        Country: 'Czech Republic',
        AppName: 'Tresom',
        Department: 'Clothing',
        CarModel: 'Town & Country',
        Corporate: 'Training',
    },
    {
        Company: 'Livefish',
        City: 'Herzliyya',
        Contruction: 'Plexiglass',
        Country: 'Israel',
        AppName: 'Hatity',
        Department: 'Outdoors',
        CarModel: 'RAV4',
        Corporate: 'Services',
    },
    {
        Company: 'Digitube',
        City: 'Hongqi',
        Contruction: 'Stone',
        Country: 'China',
        AppName: 'Otcom',
        Department: 'Music',
        CarModel: 'Lancer Evolution',
        Corporate: 'Sales',
    },
    {
        Company: 'Midel',
        City: 'Kamakura',
        Contruction: 'Granite',
        Country: 'Japan',
        AppName: 'Daltfresh',
        Department: 'Outdoors',
        CarModel: 'Quest',
        Corporate: 'Marketing',
    },
    {
        Company: 'Rhyloo',
        City: 'Yahil’nytsya',
        Contruction: 'Glass',
        Country: 'Ukraine',
        AppName: 'Duobam',
        Department: 'Clothing',
        CarModel: 'Cougar',
        Corporate: 'Sales',
    },
    {
        Company: 'Innojam',
        City: 'Mandalay',
        Contruction: 'Plexiglass',
        Country: 'Myanmar',
        AppName: 'Mat Lam Tam',
        Department: 'Electronics',
        CarModel: 'Galant',
        Corporate: 'Management',
    },
];
const megaMenuDemo: MegamenuItem[] = [
    {
        id: ncNanoId(),
        image: 'https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        title: 'Europe',
        items: megamenu.map((i) => ({
            id: ncNanoId(),
            href: '/#',
            name: i.Country,
        })),
    },
    {
        id: ncNanoId(),
        image: 'https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        title: 'Asia',
        items: megamenu.map((i) => ({
            id: ncNanoId(),
            href: '/#',
            name: i.City,
        })),
    },
];

export const NAVIGATION_DEMO: NavItemType[] = [
    {
        id: ncNanoId(),
        href: '/',
        name: 'App',
        isNew: true,
    },
    {
        id: ncNanoId(),
        href: '/places',
        name: 'Browse Places',
        type: 'megaMenu',
        megaMenu: megaMenuDemo,
    },
    {
        id: ncNanoId(),
        href: '/how-it-works',
        name: 'How it works',
        isNew: true,
    },
    {
        id: ncNanoId(),
        href: '/faq',
        name: 'FAQ',
        isNew: true,
    },
    // {
    //     id: ncNanoId(),
    //     href: '/listing-stay',
    //     name: 'Listing Page',
    //     type: 'dropdown',
    //     isNew: true,
    //     children: [
    //         {
    //             id: ncNanoId(),
    //             href: '/listing-stay',
    //             name: 'Stay listing',
    //             type: 'dropdown',
    //             children: [
    //                 { id: ncNanoId(), href: '/listing-stay', name: 'Stay page' },
    //                 {
    //                     id: ncNanoId(),
    //                     href: '/listing-stay-map',
    //                     name: 'Stay page (map)',
    //                 },
    //                 { id: ncNanoId(), href: '/listing-stay-detail', name: 'Stay Detail' },
    //             ],
    //         },
    //
    //         //
    //         {
    //             id: ncNanoId(),
    //             href: '/listing-experiences',
    //             name: 'Experiences listing',
    //             type: 'dropdown',
    //             children: [
    //                 {
    //                     id: ncNanoId(),
    //                     href: '/listing-experiences',
    //                     name: 'Experiences page',
    //                 },
    //                 {
    //                     id: ncNanoId(),
    //                     href: '/listing-experiences-map',
    //                     name: 'Experiences page (map)',
    //                 },
    //                 {
    //                     id: ncNanoId(),
    //                     href: '/listing-experiences-detail',
    //                     name: 'Experiences Detail',
    //                 },
    //             ],
    //         },
    //
    //         //
    //         {
    //             id: ncNanoId(),
    //             href: '/listing-car',
    //             name: 'Cars listing',
    //             type: 'dropdown',
    //             children: [
    //                 { id: ncNanoId(), href: '/listing-car', name: 'Cars page' },
    //                 { id: ncNanoId(), href: '/listing-car-map', name: 'Cars page (map)' },
    //                 { id: ncNanoId(), href: '/listing-car-detail', name: 'Car Detail' },
    //             ],
    //         },
    //
    //         //
    //         {
    //             id: ncNanoId(),
    //             href: '/listing-real-estate',
    //             name: 'Real Estate Listings',
    //             isNew: true,
    //             type: 'dropdown',
    //             children: [
    //                 {
    //                     id: ncNanoId(),
    //                     href: '/listing-real-estate',
    //                     name: 'Real Estate Listings',
    //                     isNew: true,
    //                 },
    //                 {
    //                     id: ncNanoId(),
    //                     href: '/listing-real-estate-map',
    //                     name: 'Real Estate Maps',
    //                     isNew: true,
    //                 },
    //             ],
    //         },
    //         //
    //         {
    //             id: ncNanoId(),
    //             href: '/listing-flights',
    //             name: 'Flights listing',
    //             isNew: true,
    //         },
    //     ],
    // },
    // {
    //     id: ncNanoId(),
    //     href: '/author',
    //     name: 'Templates',
    //     type: 'dropdown',
    //     children: templatesChildrenMenus,
    // },
    //
    // {
    //     id: ncNanoId(),
    //     href: '/blog',
    //     name: 'Other pages',
    //     type: 'dropdown',
    //     children: otherPageChildMenus,
    // },
];
