'use client';

import { usePathname } from 'next/navigation';
import React from 'react';

import SocialsList1 from '@/app/components/shared/SocialsList1';

// import Input from '@/app/components/shared/Input';
// import ButtonCircle from '@/app/components/shared/Button/ButtonCircle';

export interface CustomLink {
    label: string;
    href: string;
    targetBlank?: boolean;
}
export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    {
        id: '5',
        title: 'About Loco Locate',
        menus: [
            { href: '#', label: 'About Us' },
            { href: '#', label: 'Press & Media' },
        ],
    },
    {
        id: '1',
        title: 'Support & Information',
        menus: [
            // { href: '#', label: 'Contact Us' },
            { href: '#', label: 'Terms of Service' },
            { href: '#', label: 'Privacy Policy' },
        ],
    },
    // {
    //     id: '2',
    //     title: 'Community & Engagement',
    //     menus: [
    //         { href: '#', label: 'Blog' },
    //         { href: '#', label: 'Community' },
    //     ],
    // },
];

const Footer: React.FC = () => {
    const pathName = usePathname();
    if (pathName === '/app') {
        return null;
    }
    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => (
        <div key={index} className="text-sm">
            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">{menu.title}</h2>
            <ul className="mt-5 space-y-4">
                {menu.menus.map(({ label, href }) => (
                    <li key={label}>
                        <a
                            key={label}
                            className="text-neutral-6000 hover:text-black dark:text-neutral-300 dark:hover:text-white"
                            href={href}
                        >
                            {label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );

    const version = process.env['NEXT_PUBLIC_VERSION'] != null ? `v${process.env['NEXT_PUBLIC_VERSION']}` : 'beta';
    return (
        <>
            <div className="nc-Footer relative border-t border-neutral-200 py-24 lg:py-24 dark:border-neutral-700">
                <div className="container grid grid-cols-2 gap-x-5 gap-y-10 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-4 lg:gap-x-10">
                    <div className="col-span-2 grid grid-cols-4 gap-5 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                        <div className="text-sm">
                            <h2
                                onClick={() => {
                                    throw new Error('This is test sentry error - LVL2');
                                }}
                                className="font-semibold text-neutral-700 dark:text-neutral-200"
                            >
                                Socials
                            </h2>
                        </div>
                        <div className="col-span-2 flex items-center md:col-span-3">
                            <SocialsList1 className="flex items-center space-x-3 lg:flex-col lg:items-start lg:space-x-0 lg:space-y-2.5" />
                        </div>
                    </div>
                    {widgetMenus.map(renderWidgetMenuItem)}
                    {/* <div key="newsletter" className="text-sm"> */}
                    {/*    <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">Newsletter</h2> */}
                    {/*    <ul className="mt-5 space-y-4"> */}
                    {/*        <form className="relative"> */}
                    {/*            <Input */}
                    {/*                required */}
                    {/*                aria-required */}
                    {/*                placeholder="Enter your email" */}
                    {/*                type="email" */}
                    {/*                rounded="rounded-full" */}
                    {/*            /> */}
                    {/*            <ButtonCircle */}
                    {/*                type="submit" */}
                    {/*                className="absolute right-[5px] top-1/2 -translate-y-1/2 transform" */}
                    {/*            > */}
                    {/*                <i className="las la-arrow-right text-xl" /> */}
                    {/*            </ButtonCircle> */}
                    {/*        </form> */}
                    {/*    </ul> */}
                    {/* </div> */}
                </div>
            </div>
            <div className="items-center border-t py-6 text-center">
                <div className="flex flex-col">
                    <span className="text-sm font-light text-neutral-600 dark:text-neutral-200">
                        © Copyright 2022-
                        {new Date().getFullYear()}{' '}
                        <a className="font-medium" target="_blank" href="https://progresak.cz" rel="noreferrer">
                            progresak.cz
                        </a>{' '}
                        All Rights Reserved.
                    </span>
                    <span className="text-sm font-light text-neutral-600 dark:text-neutral-200">
                        <small>
                            Version:&nbsp;
                            {version}
                        </small>
                    </span>
                </div>
            </div>
        </>
    );
};

export default Footer;
