import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import LLLogo from './ll_logo.png';

export interface LogoProps {
    className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = '' }) => (
    <Link
        href="/"
        className={`ttnc-logo m-0 inline-block p-0 text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
        {/* <LogoSvgLight /> */}
        <Image src={LLLogo} className="lg:max-w- m-0 block max-w-[48px] p-0" alt="Loco Locate" priority />
        {/* <LogoSvg /> */}
        {/* THIS USE FOR MY CLIENT */}
        {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
        {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
);

export default Logo;
