import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/components/Header.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/styles/index.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/fonts/line-awesome-1.3.0/css/line-awesome.css");
