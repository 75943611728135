'use client';

import Link from 'next/link';
import React, { FC } from 'react';

import ButtonPrimary from '@/app/components/shared/Button/ButtonPrimary';
import LangDropdown from '@/app/components/shared/LangDropdown';
import Logo from '@/app/components/shared/Logo/Logo';
import Navigation from '@/app/components/shared/Navigation/Navigation';
import SearchDropdown from '@/app/components/shared/SearchDropdown';
import { HiddenFeature } from '@/enums/HiddenFeature';
import { useHiddenFeature } from '@/hooks/useHiddenFeature';

const Header: FC = () => (
    <div className="nc-Header nc-header-bg sticky left-0 right-0 top-0 z-40 w-full shadow-sm dark:border-b dark:border-neutral-700">
        <div className="nc-MainNav1 relative z-10">
            <div className="relative flex items-center justify-between px-4 py-4 lg:container lg:py-5">
                <div className="flex flex-1 items-center space-x-10 md:flex">
                    <Logo />
                    <Link href="/">
                        <h3 className="text-xl sm:text-2xl">Loco Locate</h3>
                    </Link>
                    <Navigation />
                </div>

                <div className="flex-shrink-0 items-center justify-end text-neutral-700 md:flex lg:flex-none dark:text-neutral-100">
                    <div className="hidden items-center space-x-0.5 xl:flex">
                        {/* <SwitchDarkMode /> */}
                        {useHiddenFeature(HiddenFeature.SearchBar) && <SearchDropdown />}
                        {useHiddenFeature(HiddenFeature.LoginRegister) && (
                            <>
                                <div className="px-1" />
                                <ButtonPrimary href="/#">Login / Register</ButtonPrimary>
                            </>
                        )}
                        <LangDropdown panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 px-0" />
                    </div>
                    <div className="flex items-center xl:hidden">
                        {/* <SwitchDarkMode /> */}
                        {/* <div className="px-0.5" /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Header;
