import React from 'react';

import { NAVIGATION_DEMO } from './constants';
import NavigationItem from './NavigationItem';

const Navigation = () => (
    <ul className="nc-Navigation relative hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1">
        {NAVIGATION_DEMO.map((item) => (
            <NavigationItem key={item.id} menuItem={item} />
        ))}
    </ul>
);

export default Navigation;
